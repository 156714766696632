import { Api } from 'utils';

class ProgrammingService {
	
	static get = async (params?: any) => Api.createResponse('admin/programming',params);
	static delete = async (params?: any) => Api.createResponse('admin/programming/delete',params);
	static create = async (params?: any) => Api.createResponse('admin/programming/create',params);
	static update = async (params?: any) => Api.createResponse('admin/programming/update',params);
	static view = async (params?: any) => Api.createResponse('admin/programming/view',params);

}

export default ProgrammingService;