import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, AddIcon, EditIcon, TrashIcon } from 'assets/icons';
import { UserService, PlanService } from 'services';
import moment from 'moment';
import ModalSelectPlan from './select-plan';
import ModalUpdateUser from './modal-update-user';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	plan_id: ''
}

class Users extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Nombre',
			'Email',
			'Teléfono',
			'Plan',
			'Fechas de Corte',
			'Mensajes Disponibles',
			''
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null,
		plans: [],
		visible_plan: false,
		user_id: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Usuarios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadPlans();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await UserService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form
		});
		for (let j = 0; j < res.users.rows.length; j++) {
			const user = res.users.rows[j];
			let dates: any = [];

			for (let i = 0; i < user.plan_users.length; i++) {
				const index = dates.map((i: any) => i.expiration_date).indexOf(user.plan_users[i].expiration_date);
				if (index == -1) {
					dates.push({
						expiration_date: user.plan_users[i].expiration_date,
						messages_remaining: user.plan_users[i].messages_remaining
					});
				}
				else {
					dates[index] = {
						...dates[index],
						messages_remaining: dates[index].messages_remaining + user.plan_users[i].messages_remaining
					}
				}
			}

			user.dates = dates;
		}
		this.setState({
			data: res.users.rows,
			last_page: res.users.count
		});
	}

	loadPlans = async () => {
		const res: any = await PlanService.getPlanList({
			withoutLoading: true
		});
		this.setState({
			plans: res.plans || []
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el usuario?',async () => {
			await UserService.admin.delete({
				user_id: item?.id
			});	
			Globals.showSuccess("Se ha eliminado el usuario correctamente");
			this.load(true);
		});
	}

	changePlan = (user_id: number) => {
		this.setState({
			user_id,
			visible_plan: true
		});
	}

	onClosePlan = () => {
		this.setState({
			user_id: null,
			visible_plan: false
		},() => this.load(true));
	}

	render() {
		const { visible, visible_plan } = this.state;

		return (
			<div id="admin-users">
				<Modal
		          className="modal-update-user"
		          visible={ visible }
		          title="Editar Usuario"
		          onClose={ () => this.onClose() }
		        >
		          <ModalUpdateUser
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-plans"
		          visible={ visible_plan }
		          title="Ver plan"
		          onClose={ () => this.onClosePlan() }
		        >
		          <ModalSelectPlan
		          	user_id={ this.state.user_id }
		            onClose={ () => this.onClosePlan() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-4">
								<Input
									value={ this.state.form.search }
									name="search"
									placeholder="Buscar"
									label="Buscar por nombre o correo"
									className="input-table"
									onSubmit={ () => this.load() }
									onChange={ (e: any) => {
										clearTimeout(this.timer);
										this.timer = setTimeout(() => {
											this.load(true);
										},1000);
										this.change(e); 
									} } />
							</div>
							<div className="col-md-4">
								<Select
									color="white"
									placeholder="Seleccionar"
									label="Plan"
									name="plan_id"
									onChange={ (e: any) => this.change(e,() => this.load(true)) }
									value={ this.state.form.plan_id }
									options={ this.state.plans.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Usuarios registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.name + ' ' + i?.lastname }</td>
								<td>{ i.email }</td>
								<td>{ i.phone }</td>
								<td className="td-change" onClick={ () => this.changePlan(i.id) }>{ i?.plan_user_admin?.plan?.name || 'Sin plan' }</td>
								<td>
									{
										i?.dates?.map((item: any) => {
											if (moment(item.expiration_date).isValid()) {
												return (
													<p>{ moment(item.expiration_date).format('DD/MM/YYYY') } ({ item.messages_remaining || 0 })</p>
												)
											}
										})
									}
								</td>
								<td>{ i?.remaining_messages }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Users);