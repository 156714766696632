import React from 'react';
import { Input, Button, Select, Textarea, Table, PaperClip, Tooltip, DatePicker } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { ProgrammingService, CampaignService } from 'services';
import {
	CampaignTitleIcon,
	CampaignListIcon,
	TrashIcon,
	CampaignUserIcon,
	UserListIcon,
	ClockIcon,
	CheckWhiteIcon
} from 'assets/icons';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

const DAYS = [
	{ value: 1, label: 'Lunes' },
	{ value: 2, label: 'Martes' },
	{ value: 3, label: 'Miércoles' },
	{ value: 4, label: 'Jueves' },
	{ value: 5, label: 'Viernes' },
	{ value: 6, label: 'Sábado' },
	{ value: 7, label: 'Domingo' },
]

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			message: '',
			title: '',
			folder_id: '',
			mode: '',
			hour: '',
			date: ''
		},
		to: [],
		folders: [],
		header: [
			'Nombre',
			'Teléfono',
			'Acción'
		],
		days: JSON.parse(JSON.stringify(DAYS))
	}

	async componentDidMount() {
		if (this.props.item) {
			const res: any = await ProgrammingService.view({
				programming_id: this.props.item?.id
			});
			if (res.programming) {
				const item: any = res.programming;
				const days = JSON.parse(item.days);

				this.setState({
					form: {
						...this.state.form,
						message: item?.message || '',
						title: item?.title || '',
						mode: item?.mode || '',
						hour: item?.hour && moment(item.hour,'HH:mm:ss').toDate() || '',
						date: item?.date && moment(item.date).isValid() && moment(item.date).toDate() || ''
					},
					days: this.state.days.map((i: any) => {
						if (days.indexOf(i.value) != -1) {
							i.active = true;
						}
						return i;
					}),
					to: res.programming?.contacts?.map((i: any) => {
						return {
							name: i.name,
							phone: i.phone
						}
					}) || []
				});
			}
		}

		this.load();
	}

	load = async () => {
		const res: any = await CampaignService.getFolders();
		this.setState({
			folders: res.folders || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.state.days.filter((i: any) => i.active).length == 0 && parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS) {
			Globals.showError("Debe seleccionar al menos 1 día");
			return;
		}

		if (parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE && !this.state.form.date) {
			Globals.showError("El campo fecha es requerido");
			return;
		}

		if (!this.state.form.mode) {
			Globals.showError("Debe seleccionar en que fecha o días se enviara");
			return;
		}

		if (this.props.item) {
			await ProgrammingService.update({
				...this.state.form,
				programming_id: this.props.item?.id,
				days: JSON.stringify(this.state.days.filter((i: any) => i.active).map((i: any) => i.value)),
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD') || '',
				hour: this.state.form.hour && moment(this.state.form.hour).format('HH:mm:ss') || '',
				to: JSON.stringify(this.state.to)
			});
			this.props.onClose();
			Globals.showSuccess("Se ha modificado el envío programado correctamente");
		}
		else {
			await ProgrammingService.create({
				...this.state.form,
				days: JSON.stringify(this.state.days.filter((i: any) => i.active).map((i: any) => i.value)),
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD') || '',
				hour: this.state.form.hour && moment(this.state.form.hour).format('HH:mm:ss') || '',
				to: JSON.stringify(this.state.to)
			});
			this.props.onClose();
			Globals.showSuccess("Se ha registrado el envío programado correctamente");
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	delete = (e: any, index: number) => {
		e.preventDefault();
		let to = [...this.state.to];
		to.splice(index,1);
		this.setState({
			to
		});
	}

	readExcel = async (file: any) => {
		const res: any = await CampaignService.readExcel({
			file: file.value,
			hasFile: true
		});
		this.setState({
			to: res.to || [],
			form: {
				...this.state.form,
				folder_id: ''
			}
		});
	}

	loadFolder = async () => {
		if (this.state.form.folder_id) {
			const res: any = await CampaignService.loadFolder({
				folder_id: this.state.form.folder_id
			});
			this.setState({
				to: res.contacts || []
			});
		}
		else {
			this.setState({
				to: []
			});
		}
	}

	setMode = async (mode: number) => {
		// @ts-ignore
		if (mode != this.state.form.mode) {
			await this.setState({
				days: JSON.parse(JSON.stringify(DAYS)),
				form: {
					...this.state.form,
					date: ''
				}
			});
		}

		this.setState({
			form: {
				...this.state.form,
				mode
			}
		});
	}

	setActiveDay = (index: number) => {
		let days: any = [...this.state.days];
		days[index].active = !days[index].active;
		this.setState({
			days
		});
	}
	
	render() {
		const characters = (this.state.form.title + (this.state.form.title && ': ') + this.state.form.message).length;

		return (
			<div id="modal-create-programming">
				<form onSubmit={ this.submit }>
					<div className="container-white">
						<div className="container-title">
							<div className="container-icon">
								<img src={ CampaignTitleIcon } />
							</div>
							<p>Remitente</p>
						</div>
						<p className="description">El nombre que ven tus destinatarios cuando reciben tu mensaje</p>
						<Input
							value={ this.state.form.title }
							name="title"
							placeholder="Escribir"
							onChange={ this.change } />
					</div>
					<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignUserIcon } />
						</div>
						<p>Destinatarios</p>
					</div>
					<p className="description">Selecionar carpeta o subir contactos</p>
						<div className="container-upload">
							<Select
								color="white"
								placeholder="Seleccionar carpeta"
								name="folder_id"
								onChange={ (e: any) => this.change(e,() => {
									this.loadFolder();
								}) }
								value={ this.state.form.folder_id }
								options={ this.state.folders.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
							<PaperClip
								successText={ `Subir contactos (Excel)` }
								pendingText={ `Subir contactos (Excel)` }
								icon={ UserListIcon }
								formats={ ['xls','xlsx'] }
								formatError="El archivo debe ser un excel válido"
								onChange={ (e: any) => {
									this.readExcel(e.target);
								} } />
						</div>
						<p className="note">Nota: En caso de subir un Excel, el sistema leera solo los valores de la columna A</p>
						{
							this.state.to.length > 0 && (
								<>
									<p className="to">{ this.state.to.length } Destinatarios</p>
									<Table title="Contactos" data={ this.state.to.length } header={ this.state.header } rightAlign={ [2] }>
										{ this.state.to.map((i: any,index: number) => {
											return (
												<tr key={ index }>
													<td>{ i.name || '-' }</td>
													<td>{ i.phone }</td>
													<td style={{ 'whiteSpace': 'nowrap', textAlign: 'right' }}>
														<Tooltip title="Eliminar">
															<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
																<img src={ TrashIcon } />
															</a>
														</Tooltip>
													</td>
												</tr>
											)
										}) }
									</Table>
								</>
							)
						}
					</div>
					<div className="container-white">
						<div className="container-title">
							<div className="container-icon">
								<img src={ CampaignListIcon } />
							</div>
							<p>Contenido del mensaje</p>
						</div>
						<Textarea
							label="Redactar el SMS"
							rows={ 4 }
							color="white"
							value={ this.state.form.title + (this.state.form.title && ': ') + this.state.form.message }
							name="content"
							placeholder="Escribir"
							onChange={ (e: any) => {
								let message = e.target.value;
								const value = this.state.form.title + (this.state.form.title && ': ');
								message = message.substr(value.length);

								this.setState({
									form: {
										...this.state.form,
										message
									}
								});
							} } />
						<div className="container-characters">
							<p className="characters-count"><strong>Caracteres:</strong> { characters }</p>
							<p className="characters-count"><strong>Cantidad de Mensajes:</strong> { Math.ceil(characters / Constants.MAX_CHARACTERS) }</p>
						</div>
					</div>
					<div className="container-white">
						<div className="container-title">
							<div className="container-icon">
								<img src={ ClockIcon } />
							</div>
							<p>Configuración</p>
						</div>
						<p className="label-message">Seleccione el método de mensaje programado</p>
						<div className="row row-modes">
							<div className="col-md-6">
								<div className="check-container" onClick={ () => this.setMode(Constants.REMINDER_MODES.DAYS) }>
									<div className={ `check ${ parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS ? 'active' : '' }` }>
										{
											parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS && (
												<img src={ CheckWhiteIcon } />
											)
										}
									</div>
									<p>Enviar los días seleccionados</p>
								</div>
							</div>
							<div className="col-md-6">
								<div className="check-container" onClick={ () => this.setMode(Constants.REMINDER_MODES.DATE) }>
									<div className={ `check ${ parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE ? 'active' : '' }` }>
										{
											parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE && (
												<img src={ CheckWhiteIcon } />
											)
										}
									</div>
									<p>Enviar un día especifico</p>
								</div>
							</div>
						</div>
						{
							parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS && (
								<div className="container-days">
									{
										this.state.days.map((item: any,index: number) => {
											return (
												<div className={ `item-day ${ item.active ? 'active' : '' }` } onClick={ () => this.setActiveDay(index) }>
													<p>{ item.label }</p>
												</div>
											)
										})
									}
								</div>
							)
						}
						<div className="row">
							{
								parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE && (
									<div className="col-md-6">
										<DatePicker
											minDate={ moment().add(1,'day').toDate() }
											label="Fecha"
											placeholder="Fecha"
											onChange={ (text: string) => {
												this.change({
													target: {
														value: text,
														name: 'date'
													}
												});
											} }
											value={ this.state.form.date }
										/>
									</div>
								)
							}
							<div className="col-md-6">
								<DatePicker
									label="Hora"
									placeholder="Hora"
									showTimeSelect={ true }
									showTimeSelectOnly={ true }
									timeCaption="Hora"
									dateFormat="hh:mm a"
									timeFormat="hh:mm a"
									onChange={ (text: string) => {
										this.change({
											target: {
												value: text,
												name: 'hour'
											}
										});
									} }
									value={ this.state.form.hour }
								/>
							</div>
						</div>
					</div>
	      			<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;