import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Globals, Constants, Permissions } from 'utils';
import Header from './header';
import $ from 'jquery';
import { Logo } from 'assets/img';
import { ArrowUp, ArrowBottom, Menu as MenuIcons, MenuIcon, MenuOpenIcon, MenuCloseIcon } from 'assets/icons';

interface MenuItem {
	name: string;
	path: string;
	submenu?: MenuItem[];
	icon?: string;
	permission?: string;
	onClick?: () => void;
	iconActive?: string;
	code: string;
	isLogout?: boolean;
}

class Menu extends React.Component<any> {

	state: any = {
		leftOpen: this.props.sidebar,
		openResponsive: false,
		items: [
			{ name: 'Usuarios', path: '/admin/users', icon: MenuIcons.Menu3, iconActive: MenuIcons.Menu3Active },
			{ name: 'Planes', path: '/admin/plans', icon: MenuIcons.Menu4, iconActive: MenuIcons.Menu4Active },
			{ name: 'Campañas', path: '/admin/campaign', icon: MenuIcons.Menu7, iconActive: MenuIcons.Menu7Active },
			{ name: 'Reportes', path: '/admin/reports', icon: MenuIcons.Menu2, iconActive: MenuIcons.Menu2Active, submenu: [
				{ name: 'Reporte de Mensajes', path: '/admin/reports/messages' },
				{ name: 'Mensajes Administrativos', path: '/admin/reports/administration-messages' },
				{ name: 'Reporte de Pagos', path: '/admin/reports/payments' }
			] }
		],
		items_client: [
			{ name: 'Inicio', path: '/client/home', icon: MenuIcons.Menu1, iconActive: MenuIcons.Menu1Active },
			{ name: 'Contactos', path: '/client/contacts', icon: MenuIcons.Menu9, iconActive: MenuIcons.Menu9Active },
			{ name: 'Campañas', path: '/client/campaign', icon: MenuIcons.Menu7, iconActive: MenuIcons.Menu7Active, submenu: [
				{ name: 'Crear Campaña SMS', path: '/client/campaign/send' },
				{ name: 'Enviar SMS Individual', path: '/client/campaign/individual' }
			] },
			{ name: 'Envíos Programados', path: '/client/programming', icon: MenuIcons.Menu10, iconActive: MenuIcons.Menu10Active },
			{ name: 'Reportes', path: '/client/reports', icon: MenuIcons.Menu2, iconActive: MenuIcons.Menu2Active, submenu: [
				{ name: 'Reporte de Mensajes', path: '/client/reports/messages' },
				{ name: 'Reporte de Pagos', path: '/client/reports/payments' }
			] },
			{ name: 'Integración API', path: '/client/api', icon: MenuIcons.Menu8, iconActive: MenuIcons.Menu8Active },
		]
	}

	toggleSidebar = () => {
	    let key: any = `leftOpen`;
	    // @ts-ignore
	    this.setState({ [key]: !this.state[key] },() => {
	    	this.props.dispatch({
	    		type: 'SET_SIDEBAR',
	    		payload: this.state.leftOpen
	    	});
	    });
	}

	checkActive = (value: string, current: string) => {
		return current.includes(value);
	}

	changeMenu = (item: MenuItem) => {
		if (item.submenu && !this.state.leftOpen) {
			this.toggleSidebar();
		}
	}

	checkPermission = (item: any) => {
		if (this.props.user?.level_id == Constants.LEVELS.ADMIN) {
			return true;
		}
		const permissions = this.props.user?.module_permissions?.filter((i: any) => i.read).map((i: any) => i.module_id) || [];
		if (Array.isArray(item.permission)) {
			let _continue = false;
			item.permission.forEach((i: any) => {
				if (permissions.indexOf(i) != -1) {
					_continue = true;
				}
			});
			return _continue;
		}
		else {
			return permissions.indexOf(item.permission) != -1;
		}		
	}

	closeNavbar = () => {
		// @ts-ignore
		$('#collapsibleNavbar').collapse('hide');
	}

	toggleResponsive = () => {
		this.setState({
	  		openResponsive: !this.state.openResponsive
	  	});
	}

	closeResponsive = () => {
		this.setState({
	  		openResponsive: !this.state.openResponsive
	  	});
	}

	render() {
		let leftOpen = !this.props.isAuth && this.state.leftOpen ? "open" : "closed";
		let items = [];

		if (this.props.user) {
			switch(this.props.user?.level_id) {
				case Constants.LEVELS.ADMIN:
					items = this.state.items;
				break;

				case Constants.LEVELS.MODERATOR:
					items = this.state.items;
				break;

				case Constants.LEVELS.CLIENT:
					items = this.state.items_client;
				break;
			}
		}

		return (
			<React.Fragment>
				{/*{
					items.length > 0 && (
						<nav className="navbar navbar-expand-lg bg-dark navbar-dark fixed-top justify-content-start">
						  <button className="navbar-toggler" type="button" onClick={ this.toggleResponsive }>
						    <img src={ MenuIcon } />
						  </button>
					    </nav>
					)
				}*/}

				<div id="left" className={ leftOpen }>
					<div className={`sidebar ${ leftOpen + ' ' + (this.state.openResponsive ? 'open-responsive' : '') }`}>
						<div className={ `shadow-close ${ this.state.openResponsive ? 'open' : '' }` } onClick={ this.closeResponsive }></div>
						<div className="container-menu">
							<div className={ `menu ${ leftOpen }` }>
								<div className="container-scroll">
									<button className={ `navbar-toggler navbar-toggler-menu ${ leftOpen }` } type="button" onClick={ () => {
										this.toggleSidebar();
									} }>
									    <img src={ leftOpen == 'open' ? MenuOpenIcon : MenuCloseIcon } />
									</button>
									{/*<button className={ `navbar-toggler navbar-toggler-menu responsive ${ leftOpen }` } type="button" onClick={ () => {
										this.toggleResponsive();
									} }>
									    <img src={ MenuIcon } />
									</button>*/}
									<ul className={ leftOpen }>
										{ items.map((i: MenuItem,index: number) => {
											return (
												<React.Fragment key={ index }>
													{
														(!i.permission || this.checkPermission(i)) && (
															<li className={ `nav-item ${ this.checkActive(i.path,this.props.history.location.pathname) ? 'active' : '' }` }>
																<Link to={ i.path } className={ `${ i.submenu && i.submenu.length > 0 ? 'contain-submenu' : '' }` } onClick={ (e: any) => {
																	if (i.onClick) {
																		e.preventDefault();
																		i.onClick();
																	}
																	else {
																		if (i.submenu && i.submenu.length > 0 && this.checkActive(i.path,this.props.history.location.pathname) && this.props.open_menu) {
																			this.props.dispatch({
																				type: 'SET_OPEN_MENU',
																				payload: false
																			});
																			e.preventDefault();
																		}
																		else {
																			this.props.dispatch({
																				type: 'SET_OPEN_MENU',
																				payload: true
																			});
																			this.changeMenu(i);
																			this.closeResponsive();
																		}
																	}																	
																} }>
																	<div className={ `container-icon-menu ${ leftOpen } ${ this.checkActive(i.path,this.props.history.location.pathname) ? 'active' : '' }` }>
																		{
																			i.icon && (
																				<img src={ this.checkActive(i.path,this.props.history.location.pathname) ? i.iconActive : i.icon } />
																			)
																		}
																	</div>												
																	<span className={ leftOpen }>
																		{ i.name }
																		{/*{
																			i.submenu && i.submenu.length > 0 && (
																				<React.Fragment>
																					{
																						this.checkActive(i.path,this.props.history.location.pathname) ? (
																							<img className="arrow-drop" src={ this.props.open_menu ? ArrowUp : ArrowBottom } />
																						) : (
																							<img className="arrow-drop" src={ ArrowBottom } />
																						)
																					}
																				</React.Fragment>
																			)
																		}*/}
																	</span>
																</Link>
																{
																	this.props.open_menu && (
																		<div className={ `${ i.submenu && i.submenu.length > 0 && this.checkActive(i.path,this.props.history.location.pathname) ? 'container-submenu' : '' }` }>
																			{
																				leftOpen != 'closed' && this.checkActive(i.path,this.props.history.location.pathname) && i.submenu && i.submenu.map((item: MenuItem,index: number) => {
																					if (!item.permission || this.checkPermission(item)) {
																						return (
																							<li className={ 'nav-item item-submenu ' + (this.props.history.location.pathname == item.path ? 'active' : '') }>
																								<Link to={ item.path } className="submenu">
																									{
																										item.icon && (
																											<img src={ item.icon } />
																										)
																									}
																									{ item.name }
																								</Link>
																							</li>
																						)
																					}
																				})
																			}
																		</div>
																	)
																}
															</li>
														)
													}												
												</React.Fragment>
											)
										}) }
									</ul>
								</div>
							</div>
						</div>
						<div className={ `container-router ${ leftOpen }` }>
							<Header className={ leftOpen + ' ' + (items.length > 0 && 'header-responsive') } toggleSidebar={ () => this.toggleSidebar() } toggleResponsive={ () => this.toggleResponsive() } />
							<div className={ `container-padding-router ${ items.length > 0 && 'container-responsive' }` }>{ this.props.children }</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
		open_menu: state.open_menu,
		sidebar: state.sidebar
	}
})(Menu);