import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, AddIcon, EditIcon, TrashIcon } from 'assets/icons';
import { ProgrammingService } from 'services';
import moment from 'moment';
import ModalCreate from './modal-create';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class Programming extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Remitente',
			'Mensaje',
			'Día',
			'Hora',
			'Acciones'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Envíos Programados',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ProgrammingService.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.programming.rows,
			last_page: res.programming.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	add = () => {
		this.setState({
			visible: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el envío programado?',async () => {
			await ProgrammingService.delete({
				programming_id: item?.id
			});	
			Globals.showSuccess("Se ha eliminado el envío programado correctamente");
			this.load(true);
		});
	}

	convertToDays = (days: string) => {
		days = JSON.parse(days);

		let _days = [];

		for (let i = 0; i < days.length; i++) {
			// @ts-ignore
			_days.push(Globals.convertDay(days[i]));
		}

		return _days.join(', ');
	}

	render() {
		const { visible } = this.state;

		return (
			<div id="programming">
				<Modal
		          className="modal-create-programming"
		          visible={ visible }
		          title={ `${ this.state.item != null ? 'Editar' : 'Creación de' } Envío Programado` }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-9">
						<div className="row">
							<div className="col-md-4">
								<Input
									value={ this.state.form.search }
									name="search"
									placeholder="Buscar"
									label="Mensaje o Remitente"
									className="input-table"
									onSubmit={ () => this.load() }
									onChange={ (e: any) => {
										clearTimeout(this.timer);
										this.timer = setTimeout(() => {
											this.load(true);
										},1000);
										this.change(e); 
									} } />
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
					<div className="col-md-3 text-right">
						<button className="btn add-btn" onClick={ this.add }>
							<img src={ AddIcon } />
							<p>Nuevo</p>
						</button>
					</div>
				</div>

				<Table title="Planes Registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.title }</td>
								<td>{ i.message }</td>
								<td>{ i.mode == Constants.REMINDER_MODES.DATE ? (moment(i.date).isValid() && moment(i.date).format('DD/MM/YYYY') || '') : this.convertToDays(i.days) }</td>
								<td>{ moment(i.hour,'HH:mm:ss').format('hh:mm A') }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon link-red" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Programming);